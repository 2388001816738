export default {
    zh:{
        register:{
            backLogin:'返回登录',
            corpInfo:'机构信息',
            personInfo:'个人信息',
            back:'上一步',
            next:'下一步',
            finish:'完成',
            registerNote:'确定填写完毕了吗?',
            regSuccess:'注册成功',
            accountCode:'登录账号',
            accountName:'账号昵称',
            accountCode_placeholder:'请输入登录账号',
            accountName_placeholder:'请输入账号昵称',
            accountCode_rule:'请输入登录账号',
            accountName_rule:'请输入账号昵称',
        }
    },
    en:{
        register:{
            backLogin:'Return to login',
            corpInfo:'Institutional Information',
            personInfo:'Personal Information',
            back:'Back',
            next:'Next',
            finish:'Finish',
            registerNote:'Are you sure you have filled it out?',
            regSuccess:'register success',
            accountCode:'account',
            accountName:'nickname',
            accountCode_placeholder:'Please enter account',
            accountName_placeholder:'Please enter your nickname',
            accountCode_rule:'Please enter account',
            accountName_rule:'Please enter your nickname',
        }
    }
}