import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import RegisterUtil ,{IRegisterDataObj} from './registerUtil';
import corpLanguage from "@/views/sysviews/organization/corp/corpLanguage";
import personLanguage from "@/views/sysviews/organization/person/personLanguage";
import language from './registerLanguage'
export default defineComponent ({
    name: 'viewFile',
    beforeRouteEnter(to:any,from:any,next:any){
        if(sessionStorage.getItem('canRegister')=='true'){
            next();
        }else{
            next('/login');
        }
    },
    props: {
        params: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(corpLanguage);
        proxy.addLangProps(personLanguage);
        proxy.addLangProps(language);
        let dataObj:IRegisterDataObj=reactive<IRegisterDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            regStep:0,
            organization:{
                form:{},
                rules: {
                    name: [utils.UtilPub.commonValidRule(proxy.$t('corp.form.name_rule'))]
                },
            },
            person:{
                form:{sex:1},
                rules: {
                    name: [utils.UtilPub.commonValidRule(proxy.$t('person.form.name_rule'))],
                    accountCode: [utils.UtilPub.commonValidRule(proxy.$t('register.accountCode_rule'))],
                    accountName: [utils.UtilPub.commonValidRule(proxy.$t('register.accountName_rule'))],
                },
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new RegisterUtil(proxy,dataObj);
        })
        onMounted(()=>{})
        //上一步
        const preStepHandler=()=>{
            if(dataObj.regStep!=0)dataObj.regStep--;
        }
        //下一步、完成
        const nextStepHandler=()=>{
            let formRefName='organizationRef';
            switch (dataObj.regStep) {
                case 0://机构信息填好之后下一步事件
                    break;
                case 1://个人信息填好之后下一步事件
                    formRefName='personRef';
                    break;
                case 2://点击完成事件
                    utils.Tools.configBox({
                        message:proxy.$t('register.registerNote'),
                        sureFn:async ()=> {
                            await dataObj.utilInst.doRegister();
                        }
                    });
                    break;
            }
            if(dataObj.regStep==0 || dataObj.regStep==1){
                dataObj.refMap.get(formRefName).validate(async (valid:BufferSource) =>{
                    if(valid){//如果验证通过,进入下一个环节
                        if(dataObj.regStep>2)dataObj.regStep=0;
                        else dataObj.regStep++;
                    }
                });
            }
        }
        return{
            ...toRefs(dataObj),preStepHandler,nextStepHandler
        }
    }
});